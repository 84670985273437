import ApiService from "@/core/services/api/v2/api.service";

const SERVICE_BY_PROVINCE_LIST_KEY = "ServiceProvinceList";
const SERVICE_FILTER_KEY = "ServiceFilter";

const ServiceService = {
  listByCompanyAndProvince(transporterCompanyID, provinceID) {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList1 = window.sessionStorage.getItem(
        SERVICE_BY_PROVINCE_LIST_KEY
      );
      let cachedList = cachedList1 ? JSON.parse(cachedList1) : {};

      if (cachedList[provinceID]) {
        resolve(cachedList[provinceID]);
      } else {
        ApiService.query(
          "serviceList/" + transporterCompanyID + "/" + provinceID
        )
          .then(response => {
            let newSublist = response.data.response.Services;

            cachedList[provinceID] = newSublist;
            window.sessionStorage.setItem(
              SERVICE_BY_PROVINCE_LIST_KEY,
              JSON.stringify(cachedList)
            );

            resolve(newSublist);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  getProfile(serviceID) {
    return new Promise(resolve => {
      ApiService.get("service/" + serviceID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(serviceID, newData) {
    return new Promise(resolve => {
      ApiService.put("service/" + serviceID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(serviceID) {
    return new Promise(resolve => {
      ApiService.delete("service/" + serviceID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("service", newData)
        .then(response => {
          // Response (if correct) holds the new ServiceID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(searchText, filterByProvinceID, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      window.sessionStorage.setItem(
        SERVICE_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("service", { params: queryParams })
        .then(response => {
          // Return full response, not just "Services" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(SERVICE_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  }
};

export default ServiceService;
