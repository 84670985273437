<template>
  <!-- begin::Services edit -->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/servicios-extra-primary.svg"
      />
      <div>
        {{
          ServiceID > 0 ? $t("SERVICES.TITLE_EDIT") : $t("SERVICES.TITLE_ADD_2")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("SERVICES.SUBTITLE") }}
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("SERVICES.NAME") }}
          </label>
        </div>
        <div class="row">
          <div
            v-for="(aLang, a) in LanguageList"
            :key="a"
            class="form-group col-lg-4"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name[aLang.lang]"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("SERVICES.PROVINCES") }}
          </label>
        </div>
        <div class="row">
          <v-select
            id="ProvincesSelect"
            multiple
            v-model="Fields.ProvincesID"
            :items="ProvinceOptions"
            chips
            small-chips
            deletable-chips
            class="form-control px-2"
            style="padding-top: 0.35em !important; height: fit-content !important"
          ></v-select>
        </div>

        <div class="manager-subtitle mt-6">
          {{ $t("SERVICES.PRICE_PER_TYPE") }}
        </div>

        <v-simple-table
          id="PriceTable"
          class="white-table border-box shadow-box pt-3 mb-6"
        >
          <template v-slot:default>
            <tbody>
              <tr v-for="vt in VehicleTypeList" :key="vt.Position">
                <td>
                  <strong>
                    {{ $t("SERVICES.TYPE") + " " + vt.Position + ": " }}
                  </strong>
                  {{ vt.Name }}
                </td>
                <td>
                  <v-text-field
                    class="form-control shortInput"
                    v-model="Fields.Price[vt.Position]"
                    type="number"
                    min="0"
                    step="0.01"
                    required
                    :rules="[rules.required, rules.min0]"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/services')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Services edit -->
</template>

<style scoped>
#PriceTable tr:hover {
  background: transparent;
}
#PriceTable td {
  border: 0 !important;
}
#PriceTable tbody td {
  padding-bottom: 1.75em;
}
.shortInput {
  max-width: 140px;
}
</style>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import i18nService from "@/core/services/i18n.service.js";
import ServiceService from "@/core/services/api/v2/service.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "ServicesEdit",
  data() {
    return {
      ServiceID: 0,
      LanguageList: i18nService.languages,
      VehicleTypeList: [],
      ProvinceOptions: [],
      Valid: true,
      Fields: {
        Name: {},
        Price: {},
        ProvincesID: []
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min0: value =>
          parseInt(value) >= 0 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 })
      }
    };
  },
  mounted() {
    this.loadProvinceOptions();
    this.loadVehicleTypeList();
  },
  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        // Parse them into v-select structure
        let list = [];
        response.forEach(p => {
          list.push({
            value: p.ProvinceID,
            text: p.Name
          });
        });

        // Sort by text
        list.sort((a, b) => {
          return a.text < b.text;
        });

        this.ProvinceOptions = list;
      });
    },

    loadVehicleTypeList() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      VehicleTypeService.listAll().then(response => {
        this.VehicleTypeList = response;

        // Edit (a certain "id") or create new?
        if (this.$route.params.id) {
          this.ServiceID = parseInt(this.$route.params.id);
          this.loadServiceData();
        } else {
          this.ServiceID = -1;

          // Set default prices for this new Service
          let price1 = [];
          response.forEach(vt => {
            price1[vt.Position] = 1;
          });
          this.Fields.Price = Object.assign({}, price1);

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    loadServiceData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      ServiceService.getProfile(this.ServiceID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/services");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.ServiceID > 0) {
          // Update Service
          ServiceService.updateProfile(this.ServiceID, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Service: we get back its ID
          ServiceService.createProfile(this.Fields).then(response => {
            if (response.ServiceID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.ServiceID = response.ServiceID;
              setTimeout(
                () =>
                  this.$router.push("/manager/services/edit/" + this.ServiceID),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
